import type { RouterConfig } from '@nuxt/schema'

export default <RouterConfig> {
    // https://router.vuejs.org/api/interfaces/routeroptions.html#routes
    routes: (_routes) => [
        {
            name: 'home',
            path: '/',
            component: () => import('~/pages/home.vue').then(r => r.default || r)
        },
        {
            path: '/agent',
            redirect: { name: 'signup-agent', query: { ref: 'agent' }}
        },
        {
            path: '/sign-up-travel-agent/success',
            name: 'signup-agent-success',
            component: () => import('~/pages/SignUpAgentSuccess.vue').then(r => r.default || r),
        },
        {
            path: '/login',
            name: 'login-traveller',
            component: () => import('~/pages/Login.vue').then(r => r.default || r),
        },
        {
            path: '/signup',
            name: 'signup-traveller',
            component: () => import('~/pages/SignUp.vue').then(r => r.default || r),
        },
        {
            path: '/account/settings',
            name: 'account-settings',
            component: () => import('~/pages/AccountSetting.vue').then(r => r.default || r),
            meta: { requiresAuth: true }
        },
        {
            path: '/enquiries',
            name: 'enquiries-listing',
            component: () => import('~/pages/EnquiriesTraveller.vue').then(r => r.default || r),
            meta: {
                requiresAuth: true,
                onScroll: true
            }
        },
        {
            path: '/bookings',
            name: 'bookings-listing',
            component: () => import('~/pages/BookingListTraveller.vue').then(r => r.default || r),
            meta: {
                requiresAuth: true,
                onScroll: true
            }
        },
        {
            path: '/search-result',
            name: 'search-result',
            component: () => import('~/pages/SearchResult.vue').then(r => r.default || r),
        },
        {
            path: '/trip-gallery',
            name: 'trip-gallery',
            component: () => import('~/pages/Gallery.vue').then(r => r.default || r),
            meta: { onScroll: true }
        },
        {
            path: '/:types_-tours',
            name: 'types-gallery',
            component: () => import('~/pages/Gallery.vue').then(r => r.default || r),
            meta: { onScroll: true }
        },

        {
            path: '/design/:steps',
            name: 'enquiry-steps',
            // component: () => import('@/pages/Enquiry/EnquiryB2C.vue'),
            redirect: {name: 'enquiry-submit-global'}
        },
        {
            path: '/:galleryTypes/design/:steps',
            name: 'gallery-enquiry-steps',
            // component: () => import('@/pages/Enquiry/EnquiryB2C.vue'),
            redirect: to => {
                return { name: 'enquiry-submit-destination', params: {galleryTypes: to.params.galleryTypes} }
            }
        },
        {
            path: '/trip-gallery/:trip/design/:steps',
            name: 'trip-gallery-enquiry-steps',
            // component: () => import('@/pages/Enquiry/EnquiryB2C.vue'),
            redirect: to => {
                return { name: 'enquiry-submit-trip', params: {trip: to.params.trip} }
            }
        },
        {
            path: '/local-designer/:name/design/:steps',
            name: 'enquiry-local-designer',
            // component: () => import('@/pages/Enquiry/EnquiryB2C.vue'),
            redirect: to => {
                return { name: 'enquiry-submit-ld', params: {name: to.params.name} }
            }
        },
        {
            path: '/withintrepid',
            name: 'withintrepid', // Submit from global page
            component: () => import('~/pages/EnquiryProcessIntrepid.vue').then(r => r.default || r),
        },
        {
            path: '/design',
            name: 'enquiry-submit-global', // Submit from global page
            component: () => import('~/pages/EnquiryProcessTraveller.vue').then(r => r.default || r),
        },
        {
            path: '/:galleryTypes/design',
            name: 'enquiry-submit-destination', // Submit from country/activity gallery page
            component: () => import('~/pages/EnquiryProcessTraveller.vue').then(r => r.default || r),
        },
        {
            path: '/trip-gallery/:trip/design',
            name: 'enquiry-submit-trip', // Submit from trip plan page
            component: () => import('~/pages/EnquiryProcessTraveller.vue').then(r => r.default || r),
        },
        {
            path: '/local-designer/:name/design',
            name: 'enquiry-submit-ld', // Submit from local designer profile page
            component: () => import('~/pages/EnquiryProcessTraveller.vue').then(r => r.default || r),
        },
        {
            path: '/traveller/tripplan/view/id/:trip(\\d+)',
            name: 'trip-plan-bookable',
            component: () => import('~/pages/TripPlanDetail.vue').then(r => r.default || r),
            meta: { requiresAuth: true }
        },
        {
            path: '/become-a-local-designer',
            name: 'become-local-designer',
            component: () => import('~/pages/BecomeLocalDesigner.vue').then(r => r.default || r),
        },
        {
            path: '/how-it-works',
            name: 'how-it-works',
            component: () => import('~/pages/HowItWorks.vue').then(r => r.default || r),
        },
        {
            path: '/about-us',
            name: 'about-us',
            component: () => import('~/pages/About.vue').then(r => r.default || r),
        },
        {
            path: '/contact-us',
            name: 'contact-us',
            component: () => import('~/pages/ContactUs.vue').then(r => r.default || r),
        },
        {
            path: '/traveller/subscribe/welcome',
            name: 'email-confirm',
            component: () => import('~/pages/EmailConfirm.vue').then(r => r.default || r)
        },
        {
            path: '/subscribe',
            name: 'blog-subscribe',
            component: () => import('~/pages/BlogSubscribe.vue').then(r => r.default || r)
        },
        {
            path: '/school-tour-details',
            name: 'school-tour-details',
            component: () => import('~/pages/SchoolTourDetails.vue').then(r => r.default || r)
        },
        {
            path: '/private-group-tours',
            name: 'private-group-tours',
            component: () => import('~/pages/CustomGroupTours.vue')
        },
        {
            path: '/custom-group-tours',
            redirect: { name: 'private-group-tours'}
        },
        {
            path: '/sign-up-travel-agent',
            name: 'signup-agent',
            component: () => import('~/pages/SignUpAgent.vue').then(r => r.default || r),
            // beforeEnter: (to, from, next) => {
            //     if (domain === 'agent') {
            //         window.location.href = `${process.env.VUE_APP_PUBLIC_DOMAIN_PUBLIC}/sign-up-travel-agent`
            //     } else {
            //         next()
            //     }
            // }
        },
        {
            path: '/bdm',
            name: 'bdm',
            component: () => import('~/pages/SignUpAgentInvitation.vue').then(r => r.default || r),
        },
        {
            path: '/press-coverpress',
            name: 'press-coverpress',
            component: () => import('~/pages/PressCoverPress.vue').then(r => r.default || r),
        },
        {
            path: '/terms-of-use-trip-plan-builder',
            name: 'terms-trip-plan-builder',
            component: () => import('~/pages/TermsOfUseTripPlanBuilder.vue').then(r => r.default || r),
        },
        {
            path: '/travel-agent-booking-incentive',
            name: 'travel-agent-booking-incentive',
            component: () => import('~/pages/TravelAgentBookingIncentive.vue').then(r => r.default || r),
        },
        {
            path: '/terms-of-use',
            name: 'terms-of-use',
            component: () => import('~/pages/TermsOfUse.vue').then(r => r.default || r),
        },
        {
            path: '/incentive-terms-and-conditions',
            name: 'incentive-terms-and-conditions',
            component: () => import('~/pages/IncentiveTermsConditions.vue').then(r => r.default || r),
        },
        {
            path: '/privacy-policy',
            name: 'privacy-policy',
            component: () => import('~/pages/PrivacyPolicy.vue').then(r => r.default || r),
        },
        {
            path: '/cookie-declaration',
            name: 'cookie-declaration',
            component: () => import('~/pages/CookieDeclaration.vue').then(r => r.default || r),
        },
        {
            path: '/data-protection',
            name: 'data-protection',
            component: () => import('~/pages/DataProtection.vue').then(r => r.default || r),
        },
        {
            path: '/traveller-protections',
            name: 'traveller-protections',
            component: () => import('~/pages/TravellerProtections.vue').then(r => r.default || r),
        },
        {
            path: '/forgot-password',
            name: 'forgot-password',
            component: () => import('~/pages/ForgotPassword.vue').then(r => r.default || r),
        },
        {
            path: '/user/reset-password',
            name: 'reset-password',
            component: () => import('~/pages/ResetPassword.vue').then(r => r.default || r),
        },
        {
            path: '/:country/travel-guide',
            name: 'country-travel-guide',
            component: () => import('~/pages/TravelGuide.vue').then(r => r.default || r),
        },
        {
            path: '/:country/local-designers',
            name: 'gallery-local-designers',
            component: () => import('~/pages/LocalDesignersGallery.vue').then(r => r.default || r)
        },
        {
            path: '/local-designer/:name',
            name: 'local-designer',
            component: () => import('~/pages/LocalDesignerProfile.vue').then(r => r.default || r),
        },
        {
            path: '/trip-gallery/:trip',
            name: 'trip-plan',
            component: () => import('~/pages/TripPlanDetail.vue').then(r => r.default || r),
        },
        {
            path: '/thankyou/:orderId(\\d+)',
            name: 'thankyou',
            component: () => import('~/pages/BookingConfirmation.vue').then(r => r.default || r),
            meta: { requiresAuth: true },
        },
        {
            path: '/thankyou/balance/:orderId(\\d+)',
            name: 'thankyou-balance',
            component: () => import('~/pages/BookingBalanceConfirmation.vue').then(r => r.default || r),
            meta: { requiresAuth: true },
        },
        {
            path: '/checkout',
            name: 'checkout',
            component: () => import('~/pages/NewCheckOut.vue').then(r => r.default || r),
            meta: {
                requiresAuth: true,
                checkout: true,
            }
        },
        {
            path: '/checkout/balance/:orderId(\\d+)',
            name: 'checkout-balance',
            component: () => import('~/pages/NewCheckOut.vue').then(r => r.default || r),
            meta: {
                requiresAuth: true,
            }
        },
        {
            path: '/design/confirmation',
            name: 'enquiry-confirmation',
            component: () => import('~/pages/EnquiryConfirmation.vue').then(r => r.default || r),
            meta: {
                requiresAuth: true,
            }
        },
        {
            path: '/bookings/details/:id(\\d+)',
            name: 'booking-details',
            component: () => import('~/pages/BookingDetails.vue').then(r => r.default || r),
            meta: {
                requiresAuth: true,
            }
        },
        {
            path: '/messages',
            name: 'messages',
            component: () => import('~/pages/ComingSoon.vue').then(r => r.default || r),
        },
        // {
        //     path: '/404.html',
        //     name: 'not-found',
        //     component: () => import('~/pages/NotFound.vue').then(r => r.default || r),
        // },
        {
            path: '/feedback/:id/point/:point',
            name: 'feedback',
            meta: { requiresAuth: true },
            component: () => import('~/pages/Feedback.vue').then(r => r.default || r)
        },
        {
            path: '/search-404',
            name:'search-404',
            component: () => import('~/pages/SearchDown.vue').then(r => r.default || r),
        },
        {
            path: '/:pathMatch(.*)*',
            name:'error',
            // component: () => import('~/error.vue').then(r => r.default || r)
            component: () => import('~/pages/NotFound.vue').then(r => r.default || r),
        },
        // {
        //     path: '/:pathMatch(.*)*',
        //     redirect: '/404.html'
        // },

    ],
    scrollBehavior: (to, from, savedPosition) => {
        // Disable scroll use meta field
        // if (to.matched.some(m => m.meta.disableScroll)) {
        //   document.getElementById('html').style.scrollBehavior = "auto"
        //   return { top: 0 }
        // }
        if(process.server) return
        const element = document.getElementById('html')
        if(!element) return
        const nuxtApp = useNuxtApp()
        const position = useState('savedPosition')
        position.value = savedPosition
        // if (to.hash) {
        //     element.style.scrollBehavior = "auto"
        //     // return { top: 0 }
        //     // const headerHeight = document.getElementById('header').clientHeight
        //     // return {
        //     //   el: to.hash,
        //     //   behavior: 'smooth',
        //     //   top: headerHeight
        //     // }
        // } else if (savedPosition) {
        //     element.style.scrollBehavior = "smooth"
        //     // return savedPosition;
        // } else if(to.matched.some(m => m.meta.onScroll)) {
        //     const isSameName = to.name == from.name
        //     element.style.scrollBehavior = "auto"
        //     // return !isSameName ? { top: 0 } : false
        // } else {
        //     element.style.scrollBehavior = "auto"
        //     // return { top: 0 }
        // }
    },
    strict: true
}
